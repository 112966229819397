import { useEffect, useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";
import { FormerGpoMultiSelect } from "./FormerGpoMultiSelect";
import { memberAPI } from "../../utility/axios";
import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import { AggregateSelector } from "./AggregateSelector";
import { ParentCompanySelector } from "./ParentCompanySelector";
import { OwnParentCheckbox } from "./OwnParentCheckbox";
import { memberSourceworkflow, memberSourceworkflow as pageWorkflow } from "./MemberSourceWorkFlow";
import { MultiLocationCheckbox } from "./MultiLocationCheckbox";

const aggregateDefault = {
  id: "",
  name: "",
};
const ParentDefault = {
  id: "",
  name: "",
};
export function MemberSourceForm(props) {
  const { id } = props;

  const [radioSelection, setRadioSelection] = useState("newStart");

  const [aggregate, setAggregate] = useState(aggregateDefault);
  const [Parent, setParent] = useState(ParentDefault);
  const [selectedFormerGpos, setSelectedFormerGpos] = useState([]);
  const [isValidated, setIsValidated] = useState(false);
  const [otherDescription, setotherDescription] = useState(null);
  const [OtherSelected, setOtherSelected] = useState(false);
  const [hasOwnParent, sethasOwnParent] =
    useState(true);
    const [hasMultiLocation, sethasMultiLocation] =
    useState(false);
  const [TotalsOtherDescriptionEnteredValidationPassed, setTotalsOtherDescriptionEnteredValidationPassed] = useState(false);
  const memberApplication = useContext(MemberApplicationContext);
  const history = useHistory();
  const formRef = useRef();
  const [memberSorceCurrentworkflow, setmemberSorceCurrentworkflow] = useState(null);
  let { authInfo } = useAzureAuthentication();
  function routeChange(path) {
    if (path) {
      history.push(`${path ?? ""}/${id}`);
    }
  }


  useEffect(() => {
    if (memberApplication.memberSourceworkflow) {
      setmemberSorceCurrentworkflow(pageWorkflow[memberApplication.memberSourceworkflow]);
    }
    if (memberApplication.id) {
      if (authInfo) initSource();
    } else {
      memberApplication.onIDChange(id);
    }
  }, [authInfo, memberApplication.memberSourceworkflow]);

  useEffect(() => {
    if (memberApplication.id) {
      if (authInfo) {
        initSource();
        setOtherSelected(false);
      }
    }
  }, [memberApplication.id]);

  function SetOtherValue(value, source, data) {
    if (value != null || value != undefined) {
      if (source == 'Fetch') {
        if (value === 0) {
          setOtherSelected(true);
          setotherDescription(data.OtherDescription);
        }
        else
          setOtherSelected(false);
      } else {
        if (value.Id === 0) {
          setOtherSelected(true);
        }
        else
          setOtherSelected(false);
      }
    } else setOtherSelected(false);
  }

  function initSource() {
    async function fetchMemberSource() {
      const res = await memberAPI.get("api/MemberSource/FetchMemberSource", {
        params: {
          id: id,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });
      const data = res.data;
      //console.log(data)

      if (data && !Array.isArray(data)) {
        setRadioSelection(data.Type ?? "newStart");
        setSelectedFormerGpos(data.FormerGpo ?? []);
        // 0 is for Other option selected from dropdown
        // when other selected we set OtherSelected state true and description is need to entered else we set false.
      const val = data.FormerGpo.find((x) => x === 0);
        SetOtherValue(val, 'Fetch', data);
        sethasMultiLocation(data.MultiLoc);
        if (data.Type === "existingCorp") {
          setAggregate(data.ParentCompany);
          sethasMultiLocation(false);
        } else if (data.Type === "existingAffiliate") {
          setAggregate(data.Affiliate);
          setParent(data.CPAffiliate);
          sethasMultiLocation(false);
        }
        sethasOwnParent(data.OwnParent);
      }
    }

    fetchMemberSource();
  }

  function onResetClick(e) {
    e.preventDefault();
    initSource();
  }

  function onBackClick(e) {
    e.preventDefault();

    if (memberApplication.hasAlternateNetworkAddress) {
      routeChange("/MemberAlternateNetworkAddress");
    } else if (memberApplication.hasAlternateRebateAddress) {
      routeChange("/MemberAlternateRebateAddress");
    } else if (memberApplication.hasAlternateCorporateAddress) {
      routeChange("/MemberAlternateCorporateAddress");
    } else {
      routeChange("/MemberService");
    }
  }

  async function onNextClick(e) {
    e.preventDefault();

    memberApplication.onMemberSourceChange(radioSelection);
    memberApplication.onOwnParentChange(hasOwnParent);
    let IsMultiLoc=false;
    if(radioSelection == "existingCorp" || radioSelection =="existingAffiliate"){
      IsMultiLoc=false;
    } else {
      IsMultiLoc=true;
    }


    async function updateSource() {
      await memberAPI.post(
        "api/MemberSource/UpdateMemberSource",
        {
          user: authInfo.userEmail,
          id: id,
          type: radioSelection,
          OtherDescription: otherDescription,
          formerGpos: selectedFormerGpos.join("|"),
          rpid: aggregate.id ?? "",
          Cpid: Parent.id ?? "",
          OwnParent:hasOwnParent,
          MultiLoc:IsMultiLoc==true? hasMultiLocation:false,
        },
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );
    }

    if (memberApplication.isReadOnly) {
      routeChange("/MemberDemographics");
    } else {
      setIsValidated(true);
      if (formRef.current.checkValidity()) {
        await updateSource();
        routeChange("/MemberDemographics");
      }
    }
  }

  function onRadioChange(e) {
    memberApplication.onMemberSourceChange(radioSelection);
    switch (radioSelection) {
      case "formerGpo":
        setSelectedFormerGpos([]);
        setOtherSelected(false);
        break;
      case "existingCorp":
        setOtherSelected(false);
      case "existingAffiliate":
        setAggregate(aggregateDefault);
        setOtherSelected(false);
        break;
      default:
        //setOtherSelected(false);
        break;
    }

    const value = e.target.value;
    setRadioSelection(value);
  }
  function GetFormerGPOOther(ids) {
    // 0 is for Other option selected from dropdown
    // when other selected we set OtherSelected state true and description is need to entered else we set false.
    const val = ids.find((x) => x.Id === 0);
    SetOtherValue(val, 'Select', null);
  }
  function onFormerGpoChange(e) {
    const ids = e.value.map((x) => x.Id);
    setSelectedFormerGpos(ids);
    GetFormerGPOOther(e.value);
  }
  const otherDescriptionChanged = (otherDescriptionValue) => {
    setotherDescription(otherDescriptionValue);
    //setTotalsOtherDescriptionEnteredValidationPassed(true);
  };
  function onOwnParentChange(e) {
      sethasOwnParent(e.target.checked);
      memberApplication.onOwnParentChange(e.target.checked);
  }
  
  function onMultiLocationChange(e) {
      sethasMultiLocation(e.target.checked);
  }

  return (
    <form
      ref={formRef}
      className={isValidated ? "was-validated" : ""}
      noValidate
    >
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          onChange={onRadioChange}
          type="radio"
          name="service"
          id="new-start-radio"
          value="newStart"
          checked={radioSelection === "newStart"}
          disabled={memberApplication.isReadOnly}
        />
        <label className="form-check-label" htmlFor="new-start-radio">
          New Start
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          onChange={onRadioChange}
          type="radio"
          name="service"
          id="former-gpo-radio"
          value="formerGpo"
          checked={radioSelection === "formerGpo"}
          disabled={memberApplication.isReadOnly}
        />
        <label className="form-check-label" htmlFor="former-gpo-radio">
          From Former GPO
        </label>
      </div>
      {memberApplication.service === 1 && (
        <>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              onChange={onRadioChange}
              type="radio"
              name="service"
              id="existing-corp-radio"
              value="existingCorp"
              checked={radioSelection === "existingCorp"}
              disabled={memberApplication.isReadOnly}
            />
            <label className="form-check-label" htmlFor="existing-corp-radio">
              Add Location to existing Corp
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              onChange={onRadioChange}
              type="radio"
              name="service"
              id="existing-affiliate-radio"
              value="existingAffiliate"
              checked={radioSelection === "existingAffiliate"}
              disabled={memberApplication.isReadOnly}
            />
            <label
              className="form-check-label"
              htmlFor="existing-affiliate-radio"
            >
              Add Location to existing Affiliate
            </label>
          </div>
        </>
      )}
      <div className="mb-3 row" />
      {
        (radioSelection != "existingCorp") && (
          <OwnParentCheckbox
            disabled={memberApplication.isReadOnly}
            service={memberApplication.service}
            hasOwnParent={hasOwnParent}
            onChange={onOwnParentChange}
          />
        )}
        {
        (memberSorceCurrentworkflow?.ControlName != "LTCNetwork" && radioSelection != "existingCorp" && radioSelection !="existingAffiliate") && (
          <MultiLocationCheckbox
            disabled={memberApplication.isReadOnly}
            service={memberApplication.service}
            hasMultiLocation={hasMultiLocation}
            onChange={onMultiLocationChange}
          />
        )}
      
      <div className="mb-3 row" />
      <FormerGpoMultiSelect
        show={radioSelection === "formerGpo"}
        value={selectedFormerGpos}
        disabled={memberApplication.isReadOnly}
        isValidated={isValidated}
        onChange={onFormerGpoChange}
        required={memberSorceCurrentworkflow?.required}
      />
      <AggregateSelector
        show={
          radioSelection === "existingAffiliate" ||
          radioSelection === "existingCorp"
        }
        onChange={(aggregate) => setAggregate(aggregate)}
        value={aggregate}
        type={radioSelection}
        disabled={memberApplication.isReadOnly}
        required={memberSorceCurrentworkflow?.required}
      />
      <div className="mb-3 row" />
{
        (radioSelection === "existingAffiliate") && (hasOwnParent === false) && (
          <>
           <label>Select the existing Corp if the member is being added to an existing Parent Company</label>
           <div className="mb-3 row" />
          <ParentCompanySelector
            show={
              radioSelection === "existingAffiliate"
            }
            onChange={(Parent) => setParent(Parent)}
            value={Parent}
            type="existingCorp"
            disabled={memberApplication.isReadOnly}
            //required={memberSorceCurrentworkflow?.required}
          />
         
          </>
        )}
      {OtherSelected === true && (
        <div className="col-sm-3 mt-2">
          <input
            type="text"
            name="otherDescription"
            className="form-control form-control-sm uppercase"
            placeholder="Other Description"
            value={otherDescription}
            autoComplete="off"
            onChange={(e) => otherDescriptionChanged(e.target.value)}
            isValidated={isValidated}
            required
            disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">
            Please Enter Other Description
          </div>
          {/* {
            !TotalsOtherDescriptionEnteredValidationPassed && (
              <div className="text-danger nowrap">Value is required</div>
            )} */}
        </div>
      )}
      <div className="mb-3 d-grid gap-2 d-md-flex justify-content-md-end">
        <button
          className="btn btn-primary"
          onClick={onResetClick}
          disabled={memberApplication.isReadOnly}
        >
          Reset
        </button>
        <button className="btn btn-primary" onClick={onBackClick}>
          Back
        </button>
        <button className="btn btn-primary" onClick={onNextClick}>
          Next
        </button>
      </div>
    </form>
  );
}
